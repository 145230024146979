import React, { Component } from 'react';
import cookiesImg from './img/cookies.png';

class Cookies extends Component {
  state = {
    cookies: false,
    display: true,
  };

  componentDidMount = () => {

    let cookiesStored = this.getCookie("cookies");

    if(cookiesStored !== null){
      this.setState({cookies: cookiesStored, display: false});
      this.loadGoogleTag();
    }
    
  }

  setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

  getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }


  setCookies = () => {
    this.setState({ cookies: true, display: false }, () => {
      this.loadGoogleTag();
      this.setCookie("cookies", true, 30);
    });
  };

  declineCookies = () => {
    this.setState({ display: false });
  };

  loadGoogleTag = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-29E57K2G17';
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', 'G-29E57K2G17');
    };
  };

  render() {
    return (
      <>
        {this.state.display && (
          <div id='cookie-holder'>
            <div id='cookies'>
              <img src={cookiesImg} height={120} alt='Cookies' />
              <h3 id='cookies-heading'>Používáme cookies!</h3>
              <p id='cookies-pp'>Tato stránka používá soubory cookies, kliknutím na souhlasím souhlasíte s jejich podmínkami.</p>
              <a id='co-jsou-susenky' href='https://www.cookie-lista.cz/co-je-cookies.html'>Co jsou cookies?</a>
              <div id='cookie-button-holder'>
                <button onClick={this.setCookies} className='cookie-button'>Souhlasím</button>
                <button onClick={this.declineCookies} className='cookie-button'>Nesouhlasím</button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Cookies;
